@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap);
.loader{
  position:fixed;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
  background:rgba(251, 254, 255, 0.9);

  z-index: 999999;
  overflow: hidden;

  display : flex;
  align-items: center;
  justify-content: center;

}

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #74b9ff;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}
* {
	font-family: 'Manrope', sans-serif;
}
body {
	margin: 0;
	padding: 0;
}

.ant-table-thead tr th {
	white-space: nowrap !important;
	text-align: center;
	background-color: #0984e3 !important;
	color: #ecf0f1 !important;
}

.ant-table-tbody tr:nth-child(even) {
	background-color: #e6e6e6;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
	background-color: #aac6e4 !important;
}

.ant-table-body {
	margin: 0 !important;
}

.ant-table-tbody tr td {
	/* white-space: nowrap; */
	overflow: hidden;
	font-size: 11px;
	max-width: 150px;
}

.label-top .ant-form-item-label {
	line-height: 1.2;
}

.bl-cl {
	color: #3c8dbc !important;
}

.red-cl {
	color: #c0392b !important;
}

.mw-100 {
	max-width: 100 !important;
}

.gr-cl {
	color: #27ae60 !important;
}

/* 
.ant-table-tbody tr td:hover{
  overflow: visible;
  white-space:normal;
  word-wrap: break-word;
} */

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 4px 11px;
}

.form-title {
	font-size: 1.8rem;
	color: #ffffff;
	text-align: center;
	display: block;
	margin-bottom: 15px;
}

